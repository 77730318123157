import HomePage from "./pages/HomePage";
import CivilPage from "./pages/CivilPage";
import FamilyLawPage from "./pages/FamilyLawPage";
import CriminalLawPage from "./pages/CriminalLawPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import routes from "./routes";

const routesConfig = routes.map((route) => ({
	...route,
	component: getComponent(route.path),
}));

function getComponent(path) {
	switch (path) {
		case "/":
			return HomePage;
		case "/civil-litigation":
			return CivilPage;
		case "/family-law":
			return FamilyLawPage;
		case "/criminal-defense":
			return CriminalLawPage;
		case "/about-us":
			return AboutPage;
		case "/contact-us":
			return ContactPage;
		default:
			return null;
	}
}

export default routesConfig;
