"use client";

import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { APP_COLOURS } from "../APP_VARS";
import { useInView } from "react-intersection-observer";

const ServiceCard = ({ service, index }) => {
	const [ref, inView] = useInView({
		threshold: 0.1,
		triggerOnce: true,
	});

	return (
		<div
			ref={ref}
			key={index}
			className={`w-full transition-all duration-1000 transform ${
				inView
					? "opacity-100 translate-y-0"
					: "opacity-0 translate-y-10"
			}`}>
			<TouchableOpacity
				onPress={() => {
					window.location.href = service.link;
				}}>
				<div className='bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition-all duration-300 transform hover:scale-[1.03]'>
					<div className='p-6'>
						<figure className='mb-6 flex justify-center'>
							<img
								src={service.imgSrc}
								alt={service.title}
								className='w-24 h-20 object-contain transition-transform hover:rotate-3 duration-300'
							/>
						</figure>
						<h4 className='text-xl font-bold text-gray-800 mb-3'>
							<Text
								style={{
									color: APP_COLOURS.BLUE,
									fontSize:
										window.innerWidth < 1000 ? 18 : 24,
								}}>
								{service.title}
							</Text>
						</h4>
						<div className='w-12 h-1 bg-blue-600 mx-auto mt-4'></div>
					</div>
				</div>
			</TouchableOpacity>
		</div>
	);
};

const ContactCard = ({ data, index }) => {
	const [ref, inView] = useInView({
		threshold: 0.1,
		triggerOnce: true,
	});

	return (
		<div
			ref={ref}
			key={index}
			className={`transition-all duration-1000 transform ${
				inView
					? "opacity-100 translate-y-0"
					: "opacity-0 translate-y-10"
			}`}>
			<div className='bg-gradient-to-br from-white to-blue-50 rounded-2xl shadow-lg p-8 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1'>
				<h3 className='text-3xl font-extrabold text-blue-900 mb-6'>
					{data.title}
				</h3>
				<div className='space-y-4'>
					<div className='text-2xl font-semibold text-gray-800'>
						{data.desc2}
					</div>
					<div className='text-2xl font-semibold text-gray-800'>
						{data.desc}
					</div>
				</div>
			</div>
		</div>
	);
};

export const Services = (props) => {
	const services = [
		{
			title: "Civil Litigation",
			imgSrc: "img/08/litigation.png",
			link: "/civil-litigation/",
		},
		{
			title: "Family Law",
			imgSrc: "img/08/family-law.png",
			link: "/family-law/",
		},
		{
			title: "Criminal Defense",
			imgSrc: "img/08/criminal-law.png",
			link: "/criminal-defense/",
		},
	];

	const other = [
		{
			title: "URGENT BAIL LINE",
			desc: <a href='tel:0798404717'>079 840 4717</a>,
			desc2: <a href='tel:0798981454'>079 898 1454</a>,
			imgSrc: "",
			link: "",
		},
		{
			title: "CONTACT US",
			desc: <a href='mailto:rs@ahslaw.co.za'>rs@ahslaw.co.za</a>,
			desc2: <a href='tel:0129912875'>012 991 2875</a>,
			imgSrc: "",
			link: "",
		},
	];

	return (
		<View
			style={{
				width: "100%",
				backgroundColor: "#f8fafc",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<div
				className='w-full bg-white shadow-sm animate-fadeIn'
				style={{
					padding: "3%",
					textAlign: "center",
				}}>
				<Text
					style={{
						fontSize: window.innerWidth < 1000 ? 26 : 40,
						fontWeight: "800",
						color: APP_COLOURS.BLUE,
						letterSpacing: "0.05em",
					}}>
					LEGAL SERVICES
				</Text>
				<div className='w-20 h-1 bg-blue-600 mx-auto mt-4'></div>
			</div>

			<div className='w-full max-w-7xl mx-auto py-16 px-4'>
				<div className='flex flex-col md:flex-row gap-8'>
					{/* Left column with service cards */}
					<div className='w-full md:w-1/2 flex flex-col gap-6'>
						{services.map((service, index) => (
							<ServiceCard
								key={index}
								service={service}
								index={index}
							/>
						))}
					</div>

					{/* Right column with info section */}
					<div className='w-full md:w-2/3'>
						<div className=' rounded-xl p-8'>
							<Text
								style={{
									color: APP_COLOURS.BLUE,
									// lineHeight: 1.8,
									fontSize:
										window.innerWidth < 1000 ? 14 : 19,
								}}>
								We offer a wide array of services which include
								but are not exclusive to assistance with{" "}
								<strong>arrests</strong>,{" "}
								<strong>bail applications</strong>,{" "}
								<strong>court bail</strong>,{" "}
								<strong>police bail</strong>,{" "}
								<strong>representation at trial</strong>,
								related representation for the matters in the{" "}
								<strong>civil courts</strong>,{" "}
								<strong>protection orders</strong>,{" "}
								<strong>appeals</strong>, and{" "}
								<strong>reviews</strong>.
							</Text>
							<Text
								style={{
									color: APP_COLOURS.BLUE,
									lineHeight: 1.8,
									marginTop: "40px",
									fontSize:
										window.innerWidth < 1000 ? 14 : 19,
								}}>
								We have a wide variety of experience in the
								following areas which include but are not
								exclusive to driving while under the influence,
								possession of illegal substances, assault,
								sexual assault, assault with the intent to
								inflict grievous bodily harm, theft, culpable
								homicide, attempted murder, arson, malicious
								injury to property, murder, robbery, commercial
								crimes and domestic violence.
							</Text>
						</div>
					</div>
				</div>
			</div>

			<div className='max-w-7xl mx-auto pb-16 px-4'>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
					{other.map((d, index) => (
						<ContactCard key={index} data={d} index={index} />
					))}
				</div>
			</div>
		</View>
	);
};

export default Services;
