import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import EnquireNow from "./EnquireNow";
import { APP_COLOURS } from "../APP_VARS";

export default class ContactBanner extends Component {
	constructor(props) {
		super(props);
		this.state = { enquireNowForm: false };
	}

	render() {
		return (
			<section className='bg-[#C59844] text-white py-10'>
				<div className='container mx-auto px-4'>
					<View
						style={{
							flex: 1,
							width: "100%",
							flexDirection:
								window.innerWidth < 1000 ? "comun" : "row",
						}}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								// alignItems: "center",
							}}>
							<Text
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
									fontSize:
										window.innerWidth < 1000 ? 19 : 35,
									fontWeight: "800",
									opacity: 0.8,
									color: APP_COLOURS.BLUE,
								}}>
								Looking for legal advice and expertise you can
								trust?
							</Text>
						</View>
						<div className='w-full md:w-1/2 flex justify-center md:justify-end items-center'>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										enquireNowForm:
											!this.state.enquireNowForm,
									});
								}}>
								<a className='bg-black text-white border-2 border-black px-8 py-3 text-3xl rounded-full transition-colors hover:bg-[#D2AE6D] hover:text-black'>
									Book a Consultation
								</a>
							</TouchableOpacity>
						</div>
					</View>

					{this.state.enquireNowForm ? (
						<EnquireNow
							{...this.props}
							{...this.state}
							nextPage={() =>
								this.setState({
									resultsPage: true,
								})
							}
							close={() =>
								this.setState({
									enquireNowForm: false,
								})
							}
						/>
					) : null}
				</div>
			</section>
		);
	}
}
