const routes = [
	{
		path: "/",
		priority: 1.0,
		changefreq: "daily",
	},
	{
		path: "/civil-litigation",
		priority: 0.8,
		changefreq: "monthly",
	},
	{
		path: "/family-law",
		priority: 0.8,
		changefreq: "monthly",
	},
	{
		path: "/criminal-defense",
		priority: 0.8,
		changefreq: "monthly",
	},
	{
		path: "/about-us",
		priority: 0.7,
		changefreq: "monthly",
	},
	{
		path: "/contact-us",
		priority: 0.7,
		changefreq: "monthly",
	},
	{
		path: "/privacy-policy",
		priority: 0.3,
		changefreq: "yearly",
	},
	{
		path: "/terms-and-conditions",
		priority: 0.3,
		changefreq: "yearly",
	},
];

module.exports = routes;
