// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import "./input.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV == "production") {
	console.log = () => {};
	console.warn = () => {};
	console.error = () => {};
	console.debug = () => {};
	console.time = () => {};
}

ReactDOM.render(
	<HelmetProvider>
		<App />
	</HelmetProvider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
