import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
	title = "Ah Stander & Agenbag Inc",
	description = "Legal advice and expertise you can trust. Available 24/7 with tailor-made legal solutions. Hire the right attorney for your case.",
	keywords = "Attorneys, Notaries, Conveyancers, Criminal Law, Family Law, Civil Litigation, Legal Advice, Divorce",
	image = "/img/ah-stander.jpg",
	url = "https://ahslaw.co.za",
	name = "Ah Stander & Agenbag Inc",
	type = "website",
}) => {
	return (
		<Helmet>
			{/* Basic meta tags */}
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta name='keywords' content={keywords} />

			{/* Open Graph / Facebook */}
			<meta property='og:type' content={type} />
			<meta property='og:url' content={url} />
			<meta property='og:title' content={title} />
			<meta property='og:description' content={description} />
			<meta property='og:image' content={image} />

			{/* Twitter */}
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:url' content={url} />
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />
			<meta name='twitter:image' content={image} />

			{/* Twitter creator */}
			<meta name='twitter:creator' content={name} />

			<link rel='sitemap' type='application/xml' href='/sitemap.xml' />
		</Helmet>
	);
};

SEO.defaultProps = {
	title: "Default Title",
	description: "Default description",
	name: "Your Name/Company",
	type: "website",
};

export default SEO;
